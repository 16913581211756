import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { UserSelectorComponent } from "@app/shared/common/user-selector-modal/user-selector-modal.component";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    ExceptionStatus,
    FileExceptionDto,
    FileExceptionProcessingRecordInput,
    FileExceptionServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";

@Component({
    selector: "ProcessExceptionModal",
    templateUrl: "./process-exception.component.html",
    styleUrls: ["./process-exception.component.less"],
})
export class ProcessExceptionComponent
    extends AppComponentBase
    implements OnInit {
    @ViewChild('UserSelectorModal') userSelectorModal: UserSelectorComponent
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    displayModal = false;
    exception = new FileExceptionDto();
    saving: boolean = false;
    files: string[] = [];
    input = new FileExceptionProcessingRecordInput();

    constructor(injector: Injector,
        private _service: FileExceptionServiceProxy,

    ) {
        super(injector);
    }

    ngOnInit(): void {

    }

    async show(item: FileExceptionDto) {
        this.exception = item;
        this.files = [];
        this.exception.mediaFiles?.split(',').forEach(it => {
            if (it != "")
                this.files.push(it);
        });
        this.displayModal = true;
    }

    deleteMedia(filename) {
        this.files = this.files.filter(f => f != filename);
        this._service.updateMedia
            (this.exception.id, this.files.map(c => c).join(','))
            .subscribe(async (response) => {
                this.notify.success("保存成功");
            });
    }

    close(): void {
        this.displayModal = false;
        this.modalSave.emit(null);
    }

    async selectHandlesUser() {
        if (this.valid()) {
            var selectedUser = await this.userSelectorModal.show();
            this.moveNext(selectedUser);
        }
    }

    async moveNext(selectedUser) {
        if (selectedUser)
            this.message.confirm(
                `确认下一处理人为${selectedUser.surname + selectedUser.name}吗?`,
                `选择处理人`,
                isConfirmed => {
                    if (isConfirmed) {
                        this.save(selectedUser.id)
                    }
                });
    }

    valid(): boolean {
        if (!this.input.remark) {
            this.message.error("请确认填写处理内容！");
            return false;
        }
        return true;
    }

    async save(userId) {
        if (this.valid()) {
            this.input.fileExceptionId = this.exception.id
            this.input.status = ExceptionStatus.处理中
            this.input.handlesPersonId = userId
            this._service
                .addExceptionProcessing(this.input)
                .pipe(finalize(() => (this.saving = false)))
                .subscribe(async (response) => {
                    this.notify.success("处理成功");
                    this.modalSave.emit();
                    this.onChange();
                    this.close();
                });

        }
    }

    async done() {
        if (this.valid()) {
            this.message.confirm(
                '确认完成吗?',
                `处理完成`,
                isConfirmed => {
                    if (isConfirmed) {
                        this.input.fileExceptionId = this.exception.id
                        this.input.status = ExceptionStatus.完成
                        this._service
                            .addExceptionProcessing(this.input)
                            .pipe(finalize(() => (this.saving = false)))
                            .subscribe(async (response) => {
                                this.notify.success("处理成功");
                                this.modalSave.emit();
                                this.onChange();
                                this.close();
                            });
                    }
                });
        }
    }

    async reject() {
        if (this.valid()) {
            this.message.confirm(
                '确认驳回吗?',
                `驳回`,
                isConfirmed => {
                    if (isConfirmed) {
                        this.input.fileExceptionId = this.exception.id
                        this.input.status = ExceptionStatus.已驳回
                        this._service
                            .addExceptionProcessing(this.input)
                            .pipe(finalize(() => (this.saving = false)))
                            .subscribe(async (response) => {
                                this.notify.success("处理成功");
                                this.modalSave.emit();
                                this.onChange();
                                this.close();
                            });
                    }
                });
        }
    }

    onChange() {
        abp.event.trigger('app.taskopt.onChange');
    }
}
