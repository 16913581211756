import { Injectable } from '@angular/core';
import { UserListDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable({
  providedIn: 'root'
})
export class UserMapService {
  public static users: UserListDto[] = [];
  constructor(private _service: UserServiceProxy) { }
  public async reload() {
    UserMapService.users = await this._service.getAllUserSimple(true, '').toPromise();
  }
  public toUserName(id: string): string {
    let res = UserMapService.users.find(p => p.id === parseInt(id));
    return res ? res.surname + res.name : '';
  }
}
