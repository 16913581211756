<!-- <p-dialog [(visible)]="visible" header="查询条件">
    <ng-template t></ng-template>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label for="code">编号</label>
                <input class="form-control" id="code" aria-describedby="code-help" [(ngModel)]="query.code" />
                <small id="code-help" class="form-text text-muted">编号系统内唯一</small>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="name">名称</label>
                <input class="form-control" id="name" aria-describedby="name-help" [(ngModel)]="query.name" />
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="model">型号</label>
                <input class="form-control" id="model" aria-describedby="model-help" [(ngModel)]="query.model" />
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="spec">规格</label>
                <input class="form-control" id="spec" aria-describedby="spec-help" [(ngModel)]="query.spec" />
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="brand">品牌</label>
                <input class="form-control" id="brand" aria-describedby="brand-help" [(ngModel)]="query.brand" />
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="category">品类</label>
                <input class="form-control" id="category" aria-describedby="category-help"
                    [(ngModel)]="query.category" />
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-primary" (click)="search()">搜索</button>
    </ng-template>
</p-dialog> -->

<div>
    <!-- <div class="mb-2">
        <button class="btn btn-secondary btn-sm" (click)="visible = true">查询</button>
    </div> -->
    <p-multiSelect class="me-2" [options]="columns" [(ngModel)]="selectedColumns"
                            optionLabel="label" appendTo="body" selectedItemsLabel="显示列总计{0}列"
                            [style]="{ 'min-width': '200px' }" placeholder="选择显示列" (onChange)="onColumnsChange($event)">
                        </p-multiSelect>
    <p-table #dataTable (onLazyLoad)="load($event)" [value]="primengTableHelper.records"
        rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [scrollable]="true" ScrollWidth="100%"
        scrollDirection="horizontal" [paginator]="false" [responsive]="primengTableHelper.isResponsive"
        [resizableColumns]="true" [(selection)]="selectedProducts" dataKey="id" [resizableColumns]="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <ng-container *ngFor="let col of getCol()">
                    <ng-container *ngIf="col.sort">
                        <th pResizableColumn [pSortableColumn]="col.field" [style]="{'text-align': col.align}">
                            <div>
                                {{ col.label }}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </div>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="!col.sort">
                        <th pResizableColumn [ngStyle]="{'text-align': col.align}">
                            {{ col.label }}
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record="$implicit">
            <tr>
                <td>
                    <p-tableCheckbox *ngIf="record.canReturnNum" [value]="record"></p-tableCheckbox>
                </td>
                <ng-container *ngFor="let col of getCol()">
                    <ng-container *ngIf="!col.type">
                        <td>{{ record[col.field] }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'location'">
                        <td>{{ record[col.field] | locationFormat }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'classification'">
                        <td>{{ record[col.field] | classificationFormat }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'date'">
                        <td>{{ record[col.field] | luxonFormat : 'yyyy-LL-dd' }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'supp'">
                        <td>{{ getSupp(record[col.field]) }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'tag'">
                        <td>
                            <span class="tab-tag" [ngClass]="col.class[record[col.field]]">
                                <ng-container *ngIf="col.value">
                                    {{ col.value[record[col.field]] ?? record[col.field] }}
                                </ng-container>
                                <ng-container *ngIf="!col.value">
                                    {{ record[col.field] }}
                                </ng-container>
                            </span>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
        {{ 'NoData' | localize }}
    </div>
    <div class="primeng-paging-container">
        <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator (onPageChange)="load($event)"
            [totalRecords]="primengTableHelper.totalRecordsCount"
            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage" [showCurrentPageReport]="true"
            [currentPageReportTemplate]="
                    'TotalRecordsCount' | localize : primengTableHelper.totalRecordsCount
                "></p-paginator>
    </div>
    <div class="p-dialog-footer">
        <button class="btn btn-primary" (click)="save()">保存</button>
    </div>
</div>