import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';

@Injectable({
    providedIn: 'root'
})
export class MultimediaDataService {
    readonly fileTypes: string[] = [
        '.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp',
        'svg',
        '.png', '.apng', '.avif',
        '.gif', '.bmp', '.tiff', 'xbm',
        'ico',
        'webp'];
    constructor(
    ) {
    }

    public isPic(filename: string) {
        let type = filename.substring(filename.lastIndexOf('.'), filename.length);
        return this.fileTypes.includes(type);
    }

    public getMultimediaPath(filename: string) {
        let type = filename.substring(filename.lastIndexOf('.'), filename.length);
        switch (type) {
            case '.doc':
                return `${AppConsts.appBaseUrl}/assets/common/images/filetype/doc.png`;
                break;
            case '.docx':
                return `${AppConsts.appBaseUrl}/assets/common/images/filetype/docx.png`;
                break;
            case '.xls':
                return `${AppConsts.appBaseUrl}/assets/common/images/filetype/xls.png`;
                break;
            case '.xlsx':
                return `${AppConsts.appBaseUrl}/assets/common/images/filetype/xlsx.png`;
                break;
            case '.7z':
                return `${AppConsts.appBaseUrl}/assets/common/images/filetype/7z.png`;
                break;
            case '.zip':
                return `${AppConsts.appBaseUrl}/assets/common/images/filetype/zip.png`;
                break;
            case '.rar':
                return `${AppConsts.appBaseUrl}/assets/common/images/filetype/rar.png`;
                break;
            default:
                if (this.isPic(filename)){
                    return `${AppConsts.remoteServiceBaseUrl}/media/${filename}`;
                } else{
                    return `${AppConsts.appBaseUrl}/assets/common/images/filetype/unknown.png`;
                };

        }

    }

    public downMultimediaPath(filename: string) {
        return `${AppConsts.remoteServiceBaseUrl}/media/${filename}`;
    }
}
// remoteServiceBaseUrl
