import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Injector, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { MultimediaDataService } from "@app/shared/common/services/multimedia-data.service";
import { AppComponentBase } from "@shared/common/app-component-base";
import { FIleDownloadServiceProxy, MultimediaServiceProxy } from "@shared/service-proxies/service-proxies";
import { FileDownloadService } from "@shared/utils/file-download.service";

@Component({
    selector: "pic-list",
    styles: [`
            :host ::ng-deep .ui-button {
            margin: .5em .5em .5em 0;
            width: 140px;
            }
            @media screen and (max-width: 40em) {
                :host ::ng-deep .ui-dialog {
                    width: 75vw !important;
                }
            }
            .ui-carousel .ui-carousel-content .ui-carousel-item .car-details > .p-grid {
            border-radius: 3px;
            margin: 0.3em;
            text-align: center;
            }
            .ui-carousel .ui-carousel-content .ui-carousel-item .car-data .car-title {
            font-weight: 700;
            font-size: 20px;
            margin-top: 24px;
            }
            .ui-carousel .ui-carousel-content .ui-carousel-item .car-data .car-subtitle {
            margin: 0 0 0.25em 0;
            }
            .ui-carousel .ui-carousel-content .ui-carousel-item .car-data button {
            margin-left: 0.5em;
            }
            .ui-carousel .ui-carousel-content .ui-carousel-item .car-data button:first-child {
                margin-left: 0;
            }
            .ui-carousel.custom-carousel .ui-carousel-dot-icon {
                width: 8px !important;
                height: 8px !important;
                border-radius: 50%;
            }
            
            .ui-carousel.ui-carousel-horizontal .ui-carousel-content .ui-carousel-item.ui-carousel-item-start .car-details > .p-grid {
                margin-left: 0.6em;
            }
            .ui-carousel.ui-carousel-horizontal .ui-carousel-content .ui-carousel-item.ui-carousel-item-end .car-details > .p-grid {
                margin-right: 0.6em;
            }
        `],
    encapsulation: ViewEncapsulation.None,
    template: `
    
              
    <div appBsModal #editUpExceptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="auditLogExceptionModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <span>预览</span>
        </h4>
      </div>
        <img *ngIf="src" [src]="src?src:''"  />
        </div>
        </div>
      </div>
  `
})
export class PicListComponent extends AppComponentBase implements OnChanges {
    @Input() files: string[] = [];
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    responsiveOptions;
    src;
    displayModal = false;
    isShowlist = false;
    constructor(injector: Injector,
        public _multimediaServices: MultimediaServiceProxy,
        public _multimedia: MultimediaDataService,
        private _fileDownloadService: FileDownloadService,
        private _file:FIleDownloadServiceProxy,
        protected httpClient: HttpClient
        ) {
        super(injector);
        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sendCount || changes.stationCode) {
            if (this.files.length)
                this.isShowlist = true;
        }
    }

    showimg(src) {
        if (this._multimedia.isPic(src)) {
            this.displayModal = true;
            this.src = this._multimedia.getMultimediaPath(src);
        }
    }

    deleteimg(src) {
        this.message.confirm(
            '确认删除吗?',
            `删除`,
            isConfirmed => {
                if (isConfirmed) {
                    this.files = this.files.filter(f => f != src);
                    this._multimediaServices.deleteFile(src)
                        .subscribe(async (response) => {
                            this.modalSave.emit(src);
                        });
                }
            });
    }

    downfile(src) {
        this.message.confirm(
            '确认下载吗?',
            `下载`,
            isConfirmed => {
                if (isConfirmed) {
                    this._file.download(src).subscribe(result=>{
                        this._fileDownloadService.downloadTempFile(result);
                      })
                }
            });
    }
    }