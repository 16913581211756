import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    UserListDto,
    UserServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: "UserSelectorModal",
    templateUrl: "./user-selector-modal.component.html",
    styleUrls: ["./user-selector-modal.component.less"],
})
export class UserSelectorComponent extends AppComponentBase implements OnInit {
    @ViewChild("editUpModal", { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    saving: boolean = false;
    users: UserListDto[];
    filterText;
    userList: UserListDto[];
    active: boolean = false;
    resolve = null
    reject = null
    constructor(injector: Injector,
        private _service: UserServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        this._service.getAllUserSimple(false,this.filterText).subscribe(result => {
            this.userList = result;
            this.search();
        });
    }

    async show(): Promise<UserListDto> {
        const self = this;
        self.active = true;
        self.modal.show();
        return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = resolve
        })
    }

    close(): void {
        const self = this;
        self.active = false;
        self.modal.hide();
        this.modalSave.emit(null);
    }
    search() {
        if (!this.filterText) {
            this.users = this.userList
            return
        }
        let reg = new RegExp(['', ...this.filterText, ''].join('.*'))
        this.users = this.userList.filter(c => {
            let fullName = c.surname + c.name + c.userName
            return reg.test(fullName)
        })

    }
    selectUser(user: UserListDto) {
        const self = this;
        self.active = false;
        self.modal.hide();
        this.modalSave.emit(user);
        this.resolve(user)
    }
}
