import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class SubHeaderService {
    info$: Observable<string[]>;
    private info = new BehaviorSubject<string[]>(['', '']);

    constructor(){
        this.info$ = this.info.asObservable();
    }

    public setTitle(title: string, subTitle: string) {
        this.info.next([title, subTitle]);
    }

    public clearTitle() {
        this.info.next(['', '']);
    }
}
