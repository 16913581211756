import {
    Component,
    Injector,
    Input,
    OnInit,
} from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    FileExceptionDto,
    FileExceptionServiceProxy,
    GetFileExceptionInput,
} from "@shared/service-proxies/service-proxies";
import { finalize } from 'rxjs/operators';

@Component({
    selector: "ListExceptionModal",
    templateUrl: "./list-exception.component.html",
    styleUrls: ["./list-exception.component.less"],
})
export class ListExceptionComponent extends AppComponentBase implements OnInit {
    @Input() taskId;
    @Input() stepId;
    @Input() groupId;

    records: FileExceptionDto[] = [];
    constructor(injector: Injector,
        private _service: FileExceptionServiceProxy,
    ) {
        super(injector);
    }
    ngOnInit(): void {
        this.getModels();
    }

    getModels() {
        let input = new GetFileExceptionInput({
            filter: '',
            taskId: this.taskId,
            stepId: this.stepId,
            currentHandlesPersonId: undefined,
            exceptionStatuses: [],
            sorting: undefined,
            maxResultCount: 999,
            skipCount: 0
        });
        this._service.list(input).subscribe((result) => {
            this.records = result.items;
        });
    }

    deleteRecord(id) {
        this.message.confirm(
            '确认删除吗?',
            `提醒`,
            isConfirmed => {
                if (isConfirmed) {
                    this.primengTableHelper.showLoadingIndicator();
                    this._service
                        .delete(id)
                        .pipe(finalize(() => (this.primengTableHelper.hideLoadingIndicator())))
                        .subscribe(() => {
                            this.notify.success("删除成功");
                            this.getModels();
                            this.onChange();
                        });
                }
            });

    }

    onChange() {
        abp.event.trigger('app.taskopt.onChange');
    }
}
