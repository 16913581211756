import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AutoCompleteCompleteEvent } from '@shared/models/AutoCompleteCompleteEvent';
import {
    ChargeInfoDto,
    ChargeQuery,
    FinProductChargeServiceProxy,
    FormConfigurationDto,
    FormConfigurationServiceProxy,
    LocationServiceProxy,
    ProductInfoDto,
    ProductInfoServiceProxy,
    ProductQuery,
    RClassificationListDto,
    RClassificationServiceProxy,
    RLocationListDto,
    RSupplierListDto,
    SupplierServiceProxy,
    UserListDto,
    UserServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { cloneDeep } from 'lodash-es';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs';

@Component({
    selector: 'prod-finselect',
    templateUrl: './select-finproduct.component.html',
    styleUrls: ['./select-finproduct.component.css'],
})
export class SelectFinProductComponent extends AppComponentBase implements OnInit, AfterViewInit {
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    columns = [
        { label: '编号', align: 'left', width: '', /* sort: true, */ field: 'code', icon: '' },
        { label: '名称', align: 'left', width: '', /* sort: true, */ field: 'name', icon: '' },
        { label: '型号', align: 'left', width: '', /* sort: true, */ field: 'model', icon: '' },
        { label: '规格', align: 'left', width: '', /* sort: true, */ field: 'spec', icon: '' },
        { label: '计量单位', align: 'left', width: '', /* sort: true, */ field: 'unit', icon: '' },
        { label: '品牌', align: 'left', width: '', /* sort: true, */ field: 'brand', icon: '' },
        { label: '供应商', align: 'left', width: '', /* sort: true, */ field: 'supplierId', icon: '', type: 'supp' },
        {
            label: '仓库',
            align: 'left',
            width: '',
            /* sort: true, */ field: 'rClassificationId',
            icon: '',
            type: 'classification',
        },
        { label: '库位', align: 'left', width: '', /* sort: true, */ field: 'rLocationId', icon: '', type: 'location' },
        { label: '库存数量', align: 'left', width: '', /* sort: true, */ field: 'f_RealInventory', icon: '' },
        { label: '经手人', align: 'left', width: '', /* sort: true, */ field: 'handlerId', icon: '', type: 'user' },
        {
            label: '入库时间',
            align: 'left',
            width: '',
            /* sort: true, */ field: 'creationTime',
            icon: '',
            type: 'date',
        },
        // { label: '生产日期', align: 'left', width: '', /* sort: true, */ field: 'effectiveDateStr', icon: '' },
        {
            label: '生产日期',
            align: 'left',
            width: '',
            /* sort: true, */ field: 'effectiveDate',
            icon: '',
            type: 'date',
        },
        { label: '保质期/天', align: 'left', width: '', /* sort: true, */ field: 'effectiveDays', icon: '' },
        { label: '单价/元', align: 'left', width: '', /* sort: true, */ field: 'price', icon: '' },
        { label: '预留1', align: 'left', width: '', /* sort: true, */ field: 'obligate1', icon: '' },
        { label: '预留2', align: 'left', width: '', /* sort: true, */ field: 'obligate2', icon: '' },
        { label: '预留3', align: 'left', width: '', /* sort: true, */ field: 'obligate3', icon: '' },
        { label: '预留4', align: 'left', width: '', /* sort: true, */ field: 'obligate4', icon: '' },
        { label: '预留5', align: 'left', width: '', /* sort: true, */ field: 'obligate5', icon: '' },
        { label: '预留6', align: 'left', width: '', /* sort: true, */ field: 'obligate6', icon: '' },
        { label: '备注', align: 'left', width: '', /* sort: true, */ field: 'remark', icon: '' },
    ];

    options: any[] = [
        { name: '包含0库存', value: false },
        { name: '不包含0库存', value: true },
    ];

    visible = false;
    logUnique = 'select-finproduct-charge';
    userId: number;
    selectedColumns = this.columns;
    classes: RClassificationListDto[] = [];
    selectClass: RClassificationListDto;
    locations: RLocationListDto[] = [];
    selectLocation: RLocationListDto;
    suppliers: RSupplierListDto[] = [];
    selectSupp: RSupplierListDto;
    handles: UserListDto[] = [];
    selectUser: UserListDto;
    query = new ChargeQuery({
        codes: undefined,
        code: '',
        name: '',
        model: '',
        spec: '',
        brand: '',
        unit: '',
        includeZero: true,
        sorting: undefined,
        skipCount: 0,
        maxResultCount: 10,
        beginDateTime: undefined,
        endDateTime: undefined,
        effectiveDays: 0,
        effectiveDateStr: undefined,
        maxPrice: 0,
        minPrice: 0,
        rLocationId: 0,
        rClassificationId: undefined,
        beginEffectiveDate: undefined,
        endEffectiveDate: undefined,
        beginLapseDate: undefined,
        endLapseDate: undefined,
        supplierId: undefined,
        handlerId: 0,
    });
    selectedProducts: ChargeInfoDto[];
    constructor(
        injector: Injector,
        private service: FinProductChargeServiceProxy,
        public ref: DynamicDialogRef,
        private _suppService: SupplierServiceProxy,
        private _userService: UserServiceProxy,
        private _classService: RClassificationServiceProxy,
        private _locationService: LocationServiceProxy,
        public config: DynamicDialogConfig,
        private _service: FormConfigurationServiceProxy
    ) {
        super(injector);
        this.userId = this.appSession.userId;
    }
    ngOnInit(): void {
        let formData = new FormConfigurationDto();
        formData.menuType = this.logUnique;
        formData.userId = this.userId;
        this._service.searchFormConfig(formData).subscribe((s) => {
            if (s.id == null) {
                return;
            }
            const selectedFomrs = s.formsConfig.split(',');
            this.selectedColumns = this.columns.filter((column) => selectedFomrs.includes(column.field));
            console.log(this.selectedColumns);
        });
        this._suppService.getComboxSupplierList('', 0).subscribe((result) => {
            this.suppliers = result;
        });
    }
    getSupp(id) {
        return this.suppliers.find((s) => s.id == id)?.supplierShortName;
    }

    async ngAfterViewInit(): Promise<void> {
        let rClassificationId = this.config.data.data;
        this.query.codes = this.config.data.codes;
        console.log(this.config.data.data);
        if (rClassificationId) {
            let result = await this._classService.getComboxClassificationList('').toPromise();
            this.classes = result;
            this.selectClass = result.find((f) => f.id === parseInt(rClassificationId));
        }
        this.load();
    }

    searchSupp(event: AutoCompleteCompleteEvent) {
        this._suppService.getComboxSupplierList(event.query, 0).subscribe((result) => {
            this.suppliers = result;
        });
    }

    searchUser(event: AutoCompleteCompleteEvent) {
        this._userService.getAllUserSimple(true, event.query).subscribe((result) => {
            this.handles = result;
            this.handles.forEach((element) => {
                element.name = element.surname + element.name;
            });
        });
    }

    searchClass(event: AutoCompleteCompleteEvent) {
        this._classService.getComboxClassificationList(event.query).subscribe((result) => {
            this.classes = result;
        });
    }
    searchLocation(event: AutoCompleteCompleteEvent) {
        if (!this.selectClass) {
            this.message.warn('请选择仓库！');
            return true;
        }
        this._locationService.getComboxLocationList(event.query, this.selectClass.id).subscribe((result) => {
            this.locations = result;
        });
    }

    search() {
        this.load();
        this.visible = false;
    }
    load(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);

            if (this.primengTableHelper.records && this.primengTableHelper.records.length > 0) {
                return;
            }
        }

        this.primengTableHelper.showLoadingIndicator();

        this.query.rClassificationId = this.selectClass?.id.toString();
        this.query.rLocationId = this.selectLocation?.id;
        this.query.maxPrice = this.query.maxPrice ? this.query.maxPrice : 0;
        this.query.minPrice = this.query.minPrice ? this.query.minPrice : 0;
        this.query.effectiveDays = this.query.effectiveDays ? this.query.effectiveDays : 0;

        this.query.sorting = this.primengTableHelper.getSorting(this.dataTable);
        this.query.skipCount = this.primengTableHelper.getSkipCount(this.paginator, event);
        this.query.maxResultCount = this.primengTableHelper.getMaxResultCount(this.paginator, event);
        this.service
            .paged(this.query)
            .pipe(
                finalize(() => {
                    this.primengTableHelper.hideLoadingIndicator();
                })
            )
            .subscribe((result) => {
                this.primengTableHelper.records = result.items.items;
                this.primengTableHelper.totalRecordsCount = result.items.totalCount;
            });
    }

    save() {
        this.ref.close(this.selectedProducts);
    }
    getCol() {
        return this.columns.filter((c) => this.selectedColumns.find((sc) => sc.field == c.field));
    }
    onColumnsChange(event: any): void {
        let formData = new FormConfigurationDto();
        const data = event.value;
        let formConfig;
        if (data.length > 0) {
            formConfig = data.map((s) => s.field).join(',');
        } else {
            formConfig = '';
        }
        formData.menuType = this.logUnique;
        formData.userId = this.userId;
        formData.formsConfig = formConfig;
        this._service.saveFormConfigs(formData).subscribe((s) => {});
    }
}
