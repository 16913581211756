import { Component, Input, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ShowPdfViewComponent } from './show-pdf-view.component';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.less']
})
export class PdfViewerComponent implements OnInit {
  @Input() customStyle = 'btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px';
  constructor(  private _modal: NzModalService) {

   }

  ngOnInit(): void {
  }
  showPdf() {
    const ref = this._modal.create({
        nzContent: ShowPdfViewComponent,
        nzTitle: '查看用户操作手册',
        nzWidth: '70%',
        nzClassName: 'lxc-pdf-modal',
        nzFooter: null,
    });
}
}
