import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { BaseModalContentComponent } from './base-modal-content/base-modal-content.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { FullScreenPageModalComponent } from './full-screen-page-modal/full-screen-page-modal.component';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    declarations: [
        BaseModalContentComponent, FullScreenPageModalComponent],
    imports: [
        CommonModule,
        FormsModule,
        NzInputModule,
        NzModalModule,
        NzButtonModule,
        NzIconModule,
        NzSelectModule,
        UtilsModule,
        NzSpinModule,
        NzPageHeaderModule
    ],
    providers: [],
    exports: [
        BaseModalContentComponent, FullScreenPageModalComponent
    ]
})
export class GeneralModalModule { }
