import { Pipe, PipeTransform } from '@angular/core';
import { UserMapService } from './services/user-map.service';

@Pipe({
  name: 'userJsonFormat'
})
export class UserJsonFormatPipe implements PipeTransform {

  constructor(private map: UserMapService) { }
  transform(value: string, ...args: unknown[]): string {
    try {
      let values = JSON.parse(value);
      let names = [];
      if (values) {
        values.forEach(element => {
          names.push(this.map.toUserName(element));
        });
      }
      return names.join(',');
    } catch (e) {
      console.log(e);
      return '';
    }
  }

}
