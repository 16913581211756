import { Pipe, PipeTransform } from '@angular/core';
import { ClassificationMapService } from './services/classification-map.service';

@Pipe({
  name: 'classificationFormat'
})
export class ClassificationFormatPipe implements PipeTransform {
  constructor(private map: ClassificationMapService) { }
  transform(value: number, ...args: unknown[]): string {
    if (value)
      return this.map.toName(value);
  }
}
