<a href="/" [class]="customHrefClass" (window:resize)="onResize()">
    <img
        alt="Logo"
        *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
        [src]="defaultLogo"
        class="h-25px app-sidebar-logo-default lxc-log-style d-lg-inline d-none"
    />
    <img
        alt="Logo"
        *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
        [src]="defaultSmallLogo"
        class="h-20px app-sidebar-logo-minimize d-lg-none d-inline"
    />
    <img
        alt="Logo"
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            currentTheme.baseSettings.menu.asideSkin +
            '&tenantId=' +
            appSession.tenant.id
        "
        class="h-25px app-sidebar-logo-default lxc-log-style d-lg-inline d-none"
    />
    <img
        alt="Logo"
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            currentTheme.baseSettings.menu.asideSkin +
            '&tenantId=' +
            appSession.tenant.id
        "
        class="h-20px app-sidebar-logo-minimize d-lg-none d-inline"
    />
</a>
