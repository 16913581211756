<div
    class="d-flex align-items-center ms-1 ms-lg-3 h-100"
    data-kt-menu-trigger="click"
    data-kt-menu-placement="bottom-end"
>
    <!--begin::Menu toggle-->
    <a
        href="javascript:;"
        class="menu-dropdown"
        [class]="customStyle"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
        (click)="showPdf()"
    >
        <span class="svg-icon theme-light-show svg-icon-2">
            <i class="pi pi-file-word" style="font-size: 1.4rem;"></i>
        </span>

    </a>
</div>