<div appBsModal #editUpExceptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="auditLogExceptionModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <span>预留</span>
        </h4>
        <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="name">指定处理人 <span class="text-danger">*</span></label>
          <button class="btn btn-primary btn-sm" (click)="selectHandlesUser()">选择</button>
          {{handleName}}
        </div>
        <div class="form-group">
          <label for="description">问题描述 <span class="text-danger">*</span></label>
          <input id="description" name="description" type="text" size="30" class="form-control"
            [(ngModel)]="editData.description" required>
        </div>
        <div class="form-group">
          <pic-list *ngIf="files.length" [files]="files" (modalSave)="deleteMedia($event)"></pic-list>
          <pic-upload (modalSave)="uploadSuccess($event)"></pic-upload>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="save()" class="btn btn-primary font-weight-bold"><i class="la la-save"></i>
          {{'Save' | localize}}</button>
        <button type="button" class="btn btn-light-primary font-weight-bold" (click)="close()">{{"Close" |
          localize}}</button>
      </div>
    </div>
  </div>
</div>
<UserSelectorModal #UserSelectorModal></UserSelectorModal>