import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-input-password',
  templateUrl: './modal-input-password.component.html',
  styleUrls: ['./modal-input-password.component.scss'],
})
export class ModalInputPasswordComponent implements OnInit {

  @Input() value: string = ''

  passwordVisible = false;
  password?: string;

  constructor(private nzModalRef: NzModalRef) {
  }

  ngOnInit() {
  }
  submit() {
    this.nzModalRef.destroy(this.value)
  }
}
