import { Pipe, PipeTransform } from '@angular/core';
import { LocationMapService } from './services/location-map.service';

@Pipe({
  name: 'locationFormat'
})
export class LocationFormatPipe implements PipeTransform {
  constructor(private map: LocationMapService) { }
  transform(value: number, ...args: unknown[]): string {
    if (value)
      return this.map.toUserName(value);
  }
}
