import { Directive, ElementRef, EventEmitter, OnInit, Output } from "@angular/core";

@Directive({
    selector: '[scrollTopBottom]',
})
export class ScrollTopBottomDirective {
    @Output() scrollTop: any = new EventEmitter<any>()
    @Output() scrollBottom: any = new EventEmitter<any>()
    constructor(private el: ElementRef) {
        this.el.nativeElement.addEventListener('scroll', (event: any) => {
            if (event.target.scrollTop == 0) {
                this.scrollTop.emit()
            }
            if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 1) {
                this.scrollBottom.emit()
            }
        })
    }
}