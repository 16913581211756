<div appBsModal #editUpModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="auditLogModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <span>选择用户</span>
        </h4>
        <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <form #auditFilterForm="ngForm" (ngSubmit)="search()">
          <div class="row align-items-center mb-4">
            <div class="col-xl-12">
              <div class="form-group m-form__group align-items-center">
                <div class="input-group">
                  <input [(ngModel)]="filterText" name="filterText" class="form-control m-input"
                    [placeholder]="l('SearchWithThreeDot')" type="text">
                  <span class="input-group-btn">
                    <button (click)="search()" class="btn btn-primary" type="submit"><i class="flaticon-search-1"
                        [attr.aria-label]="l('Search')"></i></button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <button style="margin: 0 5px;" *ngFor="let user of users" (click)="selectUser(user)" pButton type="button"
          [label]="user.surname + user.name +'/'+ user.userName" class="ui-button-secondary"></button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light-primary font-weight-bold" (click)="close()">{{"Close" |
          localize}}</button>
      </div>
    </div>
  </div>
</div>