<button type="button" *ngIf="'Pages.Task.MakeException' | permission" class="btn btn-primary"
  (click)="AddTaskExceptionModal.show(taskId,stepId,groupId)">添加</button>
<div class="row align-items-center">
  <div class="primeng-datatable-container">
    <p-table #dataTableBopTrees [value]="records" [paginator]="false" [lazy]="true" scrollable="true"
      ScrollWidth="100%">
      <ng-template pTemplate="header">
        <tr>
          <th style="width:180px"></th>
          <!--<th> 编号 </th>
           <th> 任务 </th> -->
          <th> 处理人 </th>
          <th> 问题描述 </th>
          <th style="width: 100px;"> 状态 </th>
          <th> {{'Time' | localize}} </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-record="$implicit">
        <tr>
          <td style="width:180px">
            <button *ngIf="'Pages.Exception.Delete' | permission" class="btn btn-danger btn-sm"
              (click)="deleteRecord(record.id)">删除</button>
            <button *ngIf="'Pages.Exception.HandExce' | permission" class="btn btn-primary btn-sm"
              (click)="processModal.show(record)">处理</button>
            <button class="btn btn-info btn-sm" (click)="viewmodal.show(record)">查看</button>
            <!-- <button id="button-basic" dropdownToggle type="button" class="btn dropdown-toggle btn-primary"
                aria-controls="dropdown-basic">
                操作<span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <li role="menuitem" *ngIf="'Pages.Proc.Tmpl.Edit' | permission" (click)="processModal.show(record)"><a
                    class="dropdown-item">处理</a></li>
                <li role="menuitem" *ngIf="'Pages.Exception.HandExce' | permission" (click)="viewmodal.show(record)"><a
                    class="dropdown-item">查看</a></li>
                <li role="menuitem" *ngIf="'Pages.Exception.Delete' | permission" (click)="deleteRecord(record.id)"><a
                    class="dropdown-item">删除</a></li>
              </ul> -->
          </td>
          <!--<td>
            {{record.number}}
          </td>
           <td>
            {{record.productCode}}
          </td> -->
          <td>
            {{record.currentHandlesPerson}}
          </td>
          <td>
            {{record.description}}
          </td>
          <td style="width: 100px;">
            <span class="label label-secondary label-inline" *ngIf="record.status==0">
              {{record.statusName}}
            </span>
            <span class="label label-info label-inline" *ngIf="record.status==1">
              {{record.statusName}}
            </span>
            <span class="label label-danger label-inline" *ngIf="record.status==2">
              {{record.statusName}}
            </span>
            <span class="label label-success label-inline" *ngIf="record.status==3">
              {{record.statusName}}
            </span>
          </td>
          <td>
            {{ record.creationTime | momentFormat:'YYYY-MM-DD HH:mm:ss'}}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="primeng-no-data" *ngIf="records.length == 0">
      {{'NoData' | localize}}
    </div>
  </div>
</div>
<AddTaskExceptionModal #AddTaskExceptionModal (modalSave)="getModels()"></AddTaskExceptionModal>
<ProcessExceptionModal #processModal (modalSave)="getModels()"></ProcessExceptionModal>
<ViewExceptionModal #viewmodal></ViewExceptionModal>