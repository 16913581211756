import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { PrimengTableHelper } from '@shared/helpers/PrimengTableHelper';
import {
    FinProductOutServiceProxy,
    FormConfigurationDto,
    FormConfigurationServiceProxy,
    FPPickStockBillListDto,
    SupplierServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs';

@Component({
    selector: 'select-order-product',
    templateUrl: './select-order-product.component.html',
    styleUrls: ['./select-order-product.component.css'],
})
export class selectOutOrderProductComponent extends AppComponentBase implements AfterViewInit, OnInit {
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    @ViewChild('dataChangeTable', { static: true }) dataChangeTable: Table;
    @ViewChild('paginatorChange', { static: true }) paginatorChange: Paginator;
    primengChangeTableHelper: PrimengTableHelper = new PrimengTableHelper();

    columns = [
        { label: '编号', align: 'left', width: '', /* sort: true, */ field: 'code', icon: '' },
        { label: '名称', align: 'left', width: '', /* sort: true, */ field: 'name', icon: '' },
        { label: '型号', align: 'left', width: '', /* sort: true, */ field: 'model', icon: '' },
        { label: '规格', align: 'left', width: '', /* sort: true, */ field: 'spec', icon: '' },
        { label: '计量单位', align: 'left', width: '', /* sort: true, */ field: 'unit', icon: '' },
        { label: '品牌', align: 'left', width: '', /* sort: true, */ field: 'brand', icon: '' },
        { label: '预留1', align: 'left', width: '', /* sort: true, */ field: 'obligate1', icon: '' },
        { label: '预留2', align: 'left', width: '', /* sort: true, */ field: 'obligate2', icon: '' },
        { label: '预留3', align: 'left', width: '', /* sort: true, */ field: 'obligate3', icon: '' },
        { label: '预留4', align: 'left', width: '', /* sort: true, */ field: 'obligate4', icon: '' },
        { label: '预留5', align: 'left', width: '', /* sort: true, */ field: 'obligate5', icon: '' },
        { label: '预留6', align: 'left', width: '', /* sort: true, */ field: 'obligate6', icon: '' },
        { label: '供应商', align: 'left', width: '', /* sort: true, */ field: 'supplierId', icon: '', type: 'supp' },
        {
            label: '仓库',
            align: 'left',
            width: '',
            /* sort: true, */ field: 'rClassificationId',
            icon: '',
            type: 'classification',
        },
        { label: '库位', align: 'left', width: '', /* sort: true, */ field: 'rLocationId', icon: '', type: 'location' },
        { label: '可退数量', align: 'left', width: '', /* sort: true, */ field: 'canReturnNum', icon: '' },
        { label: '数量', align: 'left', width: '', /* sort: true, */ field: 'outNum', icon: '' },
        {
            label: '生产日期',
            align: 'left',
            width: '',
            /* sort: true, */ field: 'effectiveDate',
            icon: '',
            type: 'date',
        },
        { label: '保质期/天', align: 'left', width: '', /* sort: true, */ field: 'effectiveDays', icon: '' },
        { label: '单价/元', align: 'left', width: '', /* sort: true, */ field: 'price', icon: '' },
        { label: '备注', align: 'left', width: '', /* sort: true, */ field: 'remark', icon: '' },
    ];

    visible = false;
    logUnique = 'select-order-bill-product';
    userId: number;
    selectedColumns = this.columns;
    type;
    id;
    suppliers: any[] = [];
    selectedProducts: FPPickStockBillListDto[];
    constructor(
        injector: Injector,
        private outservice: FinProductOutServiceProxy,
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private _suppService: SupplierServiceProxy,
        private _service: FormConfigurationServiceProxy
    ) {
        super(injector);
        this.userId = this.appSession.userId;
    }
    ngOnInit(): void {
        this.type = this.config.data.type;
        this.id = this.config.data.id;
        let formData = new FormConfigurationDto();
        formData.menuType = this.logUnique;
        formData.userId = this.userId;
        this._service.searchFormConfig(formData).subscribe((s) => {
            if (s.id == null) {
                return;
            }
            const selectedFomrs = s.formsConfig.split(',');
            this.selectedColumns = this.columns.filter((column) => selectedFomrs.includes(column.field));
            console.log(this.selectedColumns);
        });
        this._suppService.getComboxSupplierList('', 0).subscribe((result) => {
            this.suppliers = result;
        });
    }

    getSupp(id) {
        return this.suppliers.find((s) => s.id === id)?.supplierShortName;
    }

    ngAfterViewInit(): void {
        this.load();
    }

    show(type) {
        this.type = type;
    }

    search() {
        this.load();
        this.visible = false;
    }

    load(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            if (this.primengTableHelper.records && this.primengTableHelper.records.length > 0) {
                return;
            }
        }

        this.primengTableHelper.showLoadingIndicator();
        this.outservice
            .getFinPickBills(
                this.id,
                '',
                this.primengTableHelper.getSorting(this.dataTable),
                this.primengTableHelper.getMaxResultCount(this.paginator, event),
                this.primengTableHelper.getSkipCount(this.paginator, event)
            )
            .pipe(
                finalize(() => {
                    this.primengTableHelper.hideLoadingIndicator();
                })
            )
            .subscribe((result) => {
                this.primengTableHelper.records = result.items;
                this.primengTableHelper.totalRecordsCount = result.totalCount;
            });
    }

    save() {
        this.ref.close(this.selectedProducts.filter((f) => f.canReturnNum));
    }
    getCol() {
        return this.columns.filter((c) => this.selectedColumns.find((sc) => sc.field == c.field));
    }
    onColumnsChange(event: any): void {
        let formData = new FormConfigurationDto();
        const data = event.value;
        let formConfig;
        if (data.length > 0) {
            formConfig = data.map((s) => s.field).join(',');
        } else {
            formConfig = '';
        }
        formData.menuType = this.logUnique;
        formData.userId = this.userId;
        formData.formsConfig = formConfig;
        this._service.saveFormConfigs(formData).subscribe((s) => {});
    }
}
