<p-dialog [(visible)]="visible" header="查询条件">
    <ng-template t></ng-template>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label for="code">编号</label>
                <input class="form-control" id="code" aria-describedby="code-help" [(ngModel)]="query.code" />
                <small id="code-help" class="form-text text-muted">编号系统内唯一</small>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="name">名称</label>
                <input class="form-control" id="name" aria-describedby="name-help" [(ngModel)]="query.name" />
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="model">型号</label>
                <input class="form-control" id="model" aria-describedby="model-help" [(ngModel)]="query.model" />
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="spec">规格</label>
                <input class="form-control" id="spec" aria-describedby="spec-help" [(ngModel)]="query.spec" />
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="brand">品牌</label>
                <input class="form-control" id="brand" aria-describedby="brand-help" [(ngModel)]="query.brand" />
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="category">计量单位</label>
                <input
                    class="form-control"
                    id="category"
                    aria-describedby="category-help"
                    [(ngModel)]="query.unit"
                />
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="suppliers">供应商</label>
                <p-autoComplete id="suppliers" [(ngModel)]="selectSupp" field="supplierShortName" class="flex-input"
                    [suggestions]="suppliers" (completeMethod)="searchSupp($event)" inputId="float-label"
                    [showEmptyMessage]="true" emptyMessage="检索的供应商不存在" [forceSelection]="true"></p-autoComplete>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="handles">经手人</label>
                <p-autoComplete id="handles" [(ngModel)]="selectUser" [inputStyleClass]="'p-inputtext-sm'"
                    field="name" appendTo="body" [suggestions]="handles" (completeMethod)="searchUser($event)"
                    [showEmptyMessage]="true" emptyMessage="检索的人员不存在" [forceSelection]="true"></p-autoComplete>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="brand">仓库</label>
                <p-autoComplete
                    id="classes"
                    [(ngModel)]="selectClass"
                    field="displayName"
                    class="flex-input"
                    [suggestions]="classes"
                    (completeMethod)="searchClass($event)"
                    inputId="float-label"
                    [showEmptyMessage]="true"
                    emptyMessage="检索的仓库不存在"
                    [forceSelection]="true"
                ></p-autoComplete>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="rLocationId">库位</label>
                <p-autoComplete
                    id="rLocationId"
                    [(ngModel)]="selectLocation"
                    [inputStyleClass]="'p-inputtext-sm'"
                    field="displayName"
                    appendTo="body"
                    [suggestions]="locations"
                    (completeMethod)="searchLocation($event)"
                    [showEmptyMessage]="true"
                    emptyMessage="检索的库位不存在"
                    [forceSelection]="true"
                ></p-autoComplete>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="minPrice">最低单价</label>
                <input
                    type="number"
                    class="form-control"
                    id="minPrice"
                    aria-describedby="minPrice-help"
                    [(ngModel)]="query.minPrice"
                />
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="maxPrice">最高单价</label>
                <input
                    type="number"
                    class="form-control"
                    id="maxPrice"
                    aria-describedby="maxPrice-help"
                    [(ngModel)]="query.maxPrice"
                />
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="beginDateTime">入库开始时间</label>
                <p-calendar
                    [(ngModel)]="query.beginDateTime"
                    id="beginDateTime"
                    appendTo="body"
                    [showIcon]="true"
                    inputId="float-label"
                ></p-calendar>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="endDateTime">入库结束时间</label>
                <p-calendar
                    [(ngModel)]="query.endDateTime"
                    id="endDateTime"
                    appendTo="body"
                    [showIcon]="true"
                    inputId="float-label"
                ></p-calendar>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="beginEffectiveDate">生产起始时间</label>
                <p-calendar
                    [(ngModel)]="query.beginEffectiveDate"
                    id="beginEffectiveDate"
                    appendTo="body"
                    [showIcon]="true"
                    inputId="float-label"
                ></p-calendar>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="endEffectiveDate">生产截至时间</label>
                <p-calendar
                    [(ngModel)]="query.endEffectiveDate"
                    id="endEffectiveDate"
                    appendTo="body"
                    [showIcon]="true"
                    inputId="float-label"
                ></p-calendar>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="beginLapseDate">时效起始时间</label>
                <p-calendar
                    [(ngModel)]="query.beginLapseDate"
                    id="beginLapseDate"
                    appendTo="body"
                    [showIcon]="true"
                    inputId="float-label"
                ></p-calendar>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label htmlFor="endLapseDate">失效截至时间</label>
                <p-calendar
                    [(ngModel)]="query.endLapseDate"
                    id="endLapseDate"
                    appendTo="body"
                    [showIcon]="true"
                    inputId="float-label"
                ></p-calendar>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="effectiveDays">到期天数</label>
                <!-- <p-inputNumber [(ngModel)]="query.effectiveDays" placeholder="清输入临期期限范围..." inputId="limitNumber"
                    [minFractionDigits]="0" [maxFractionDigits]="6" [min]="0" mode="decimal"></p-inputNumber> -->
                <input
                    type="number"
                    class="form-control"
                    id="effectiveDays"
                    aria-describedby="effectiveDays-help"
                    [(ngModel)]="query.effectiveDays"
                />
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <!-- <label htmlFor="endLapseDate">失效截至时间</label>
                <p-calendar [(ngModel)]="query.endLapseDate" id="endLapseDate" appendTo="body" [showIcon]="true"
                    inputId="float-label"></p-calendar> -->
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col">
            <div class="form-group">
                <label htmlFor="allow">是否包括零库存</label>
                <p-selectButton
                    [options]="options"
                    optionLabel="name"
                    optionValue="value"
                    [(ngModel)]="query.includeZero"
                ></p-selectButton>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-primary" (click)="search()">搜索</button>
    </ng-template>
</p-dialog>

<div>
    <div class="mb-2">
        <button class="btn btn-secondary btn-sm" (click)="visible = true">查询</button>
    </div>
    <p-multiSelect class="me-2" [options]="columns" [(ngModel)]="selectedColumns" optionLabel="label"
                    appendTo="body" selectedItemsLabel="显示列总计{0}列" [style]="{ 'min-width': '200px' }"
                    placeholder="选择显示列" (onChange)="onColumnsChange($event)">
    </p-multiSelect>
    <p-table
        #dataTable
        (onLazyLoad)="load($event)"
        [value]="primengTableHelper.records"
        rows="{{ primengTableHelper.defaultRecordsCountPerPage }}"
        [scrollable]="true"
        ScrollWidth="100%"
        scrollDirection="horizontal"
        [paginator]="false"
        [responsive]="primengTableHelper.isResponsive"
        [resizableColumns]="true"
        [(selection)]="selectedProducts"
        dataKey="id"
        class="resizableTable"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <ng-container *ngFor="let col of getCol()">
                    <ng-container *ngIf="col.sort">
                        <th pResizableColumn [pSortableColumn]="col.field" [style]="{ 'text-align': col.align }">
                            <div>
                                {{ col.label }}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </div>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="!col.sort">
                        <th pResizableColumn [ngStyle]="{ 'text-align': col.align }">
                            {{ col.label }}
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record="$implicit">
            <tr>
                <td>
                    <p-tableCheckbox [value]="record"></p-tableCheckbox>
                </td>
                <ng-container *ngFor="let col of getCol()">
                    <ng-container *ngIf="!col.type">
                        <td>{{ record[col.field] }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'supp'">
                        <td>{{ getSupp(record[col.field]) }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'user'">
                        <td>{{ record[col.field] | userFormat }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'location'">
                        <td>{{ record[col.field] | locationFormat }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'classification'">
                        <td>{{ record[col.field] | classificationFormat }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'date'">
                        <td>{{ record[col.field] | luxonFormat : 'yyyy-LL-dd' }}</td>
                    </ng-container>
                    <ng-container *ngIf="col.type === 'tag'">
                        <td>
                            <span class="tab-tag" [ngClass]="col.class[record[col.field]]">
                                <ng-container *ngIf="col.value">
                                    {{ col.value[record[col.field]] ?? record[col.field] }}
                                </ng-container>
                                <ng-container *ngIf="!col.value">
                                    {{ record[col.field] }}
                                </ng-container>
                            </span>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-template>
    </p-table>
    <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
        {{ 'NoData' | localize }}
    </div>
    <div class="primeng-paging-container">
        <p-paginator
            [rows]="primengTableHelper.defaultRecordsCountPerPage"
            #paginator
            (onPageChange)="load($event)"
            [totalRecords]="primengTableHelper.totalRecordsCount"
            [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="'TotalRecordsCount' | localize : primengTableHelper.totalRecordsCount"
        ></p-paginator>
    </div>
    <div class="p-dialog-footer">
        <button class="btn btn-primary" (click)="save()">保存</button>
    </div>
</div>
