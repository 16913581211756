<div appBsModal #editUpExceptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="auditLogExceptionModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <span>处理</span>
        </h4>
        <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="code">问题描述: <span class="text-danger"></span></label>
          {{exception.description}}
        </div>
        <div class="form-group">
          <label for="remark">处理意见 <span class="text-danger">*</span></label>
          <input id="remark" name="remark" type="text" size="30" class="form-control" [(ngModel)]="input.remark"
            required>
        </div>
        <div class="form-group">
          <pic-list *ngIf="files.length" [files]="files" (modalSave)="deleteMedia($event)"></pic-list>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="'Pages.Exception.RejectExce' | permission" type="button" (click)="reject()"
          class="btn btn-danger font-weight-bold">
          驳回</button>
        <button *ngIf="'Pages.Exception.OverExce' | permission" type="button" (click)="done()"
          class="btn btn-light font-weight-bold">
          结束</button>
        <button *ngIf="'Pages.Exception.HandExce' | permission" type="button" (click)="selectHandlesUser()"
          class="btn btn-primary font-weight-bold">
          流转</button>
        <button type="button" class="btn btn-light-primary font-weight-bold" (click)="close()">{{"Close" |
          localize}}</button>
      </div>
    </div>
  </div>
</div>
<UserSelectorModal #UserSelectorModal></UserSelectorModal>