import { Injectable } from '@angular/core';
import { RClassificationListDto, RClassificationServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable({
  providedIn: 'root'
})
export class ClassificationMapService {
  public static classes: RClassificationListDto[] = [];
  constructor(private _service: RClassificationServiceProxy) { }
  public async reload() {
    ClassificationMapService.classes = await this._service.getComboxClassificationList("").toPromise();
  }
  public toName(id: number): string {
    let res = ClassificationMapService.classes.find(p => p.id === parseInt(id.toString()));
    return res ? res.displayName : '';
  }
}
