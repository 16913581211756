import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    FileExceptionDto,
    FileExceptionProcessingRecordDto,
    FileExceptionServiceProxy,
} from "@shared/service-proxies/service-proxies";
import * as moment from "moment";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: "ViewExceptionModal",
    templateUrl: "./view-exception.component.html",
    styleUrls: ["./view-exception.component.less"],
})
export class ViewExceptionComponent
    extends AppComponentBase
    implements OnInit {
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    displayModal = false;
    saving: boolean = false;
    exception: FileExceptionDto = new FileExceptionDto();
    processRecord: FileExceptionProcessingRecordDto[] = []
    statusData: any[];
    files: string[] = [];
    constructor(injector: Injector,
        private _service: FileExceptionServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {

    }

    async show(item: FileExceptionDto) {
        this.displayModal = true;
        this.exception = item;
        this.files = [];
        this.exception.mediaFiles?.split(',').forEach(it => {
            if (it != "")
                this.files.push(it);
        });
        this._service.getExceptionProcessing(this.exception.id).subscribe((result) => {
            this.processRecord = result;
            this.statusData = [];
            this.processRecord.forEach((element) => {
                let time = moment(element.creationTime).format("lll");
                let name = element.creatorUser;
                let state;
                switch (element.status) {
                    case 0:
                        state = "In progress";
                        break;
                    case 1:
                        state = "In progress";
                        break;
                    case 2:
                        state = "Reject";
                        break;
                    case 3:
                        state = "Completed";
                        break;
                }
                let process = element.remark;

                let step = `状态:${element.statusName},<br>操作人员:${name},<br>时间:${time}`;
                if (process)
                    step = step + `,<br>描述:${process}`;
                let tt = {
                    step: step,
                    status: state,
                };
                this.statusData.push(tt);
            });
        });
    }

    deleteMedia(filename) {
        this.files = this.files.filter(f => f != filename);
        this._service.updateMedia
            (this.exception.id, this.files.map(c => c).join(','))
            .subscribe(async (response) => {
                this.notify.success("保存成功");
            });
    }

    close(): void {
        this.displayModal = false;
        this.modalSave.emit(null);
    }
}
