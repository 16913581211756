import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ModalInputSelectComponent } from './modal-input-select/modal-input-select.component';
import { ModalInputTextComponent } from './modal-input-text/modal-input-text.component';
import { ModalViewTextComponent } from './modal-view-text/modal-view-text.component';
import { ModalInputPasswordComponent } from './modal-input-password/modal-input-password.component';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    declarations: [
        ModalInputSelectComponent,
        ModalInputTextComponent,
        ModalViewTextComponent,
        ModalInputPasswordComponent],
    imports: [
        CommonModule,
        FormsModule,
        NzInputModule,
        NzModalModule,
        NzButtonModule,
        NzIconModule,
        NzSelectModule,
        UtilsModule],

    providers: [],
    exports: [
        ModalInputSelectComponent,
        ModalInputTextComponent,
        ModalViewTextComponent,
        ModalInputPasswordComponent
    ],
    bootstrap: [],
})
export class ModalInputModule { }
