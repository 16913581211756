import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            // new AppMenuItem('仓储总览', 'Pages.DataScreen', 'flaticon-dashboard', '/app/admin/hostDashboard'),
            new AppMenuItem('综合分析', 'Pages.DataScreen', 'flaticon-line-graph', '/app/dashboard/main'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            // new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('入库管理', 'Pages.FinProduct.Into', 'flaticon-tabs', '/app/finproduct/into', [],
                [
                    new AppMenuItem(
                        '手动入库',
                        'Pages.FinProduct.Into.Handle',
                        'flaticon-folder-4',
                        '/app/finproduct/intohandle/'
                    ),
                    new AppMenuItem(
                        '入库退货',
                        'Pages.FinProduct.Into.Return',
                        'flaticon-bag',
                        '/app/finproduct/intoreturn/'
                    ),
                    new AppMenuItem(
                        '入库换货',
                        'Pages.FinProduct.Into.Change',
                        'flaticon-interface-4',
                        '/app/finproduct/intochange/'
                    ),
                    new AppMenuItem(
                        '入库查询',
                        'Pages.FinProduct.Into.Select',
                        'flaticon-list-2',
                        '/app/inbound/query'
                    ),
                    // new AppMenuItem(
                    //     '入库统计',
                    //     'Pages.FinProduct.Into.Statistics',
                    //     'flaticon-interface-8',
                    //     '/app/finproduct/into/statistics/'
                    // ),
                ]
            ),
            new AppMenuItem('库存管理', 'Pages.StockManage', 'flaticon-list-1', '/app/stockmanage', [],
                [
                    new AppMenuItem(
                        '库存状况',
                        'Pages.StockManage.Info',
                        'flaticon-list-2',
                        '/app/inventory/state'
                    ),
                    new AppMenuItem(
                        '库存上下限',
                        'Pages.StockManage.LimitSetting',
                        'flaticon-interface-6',
                        '/app/inventory/limit-setting'
                    ),
                ]
            ),
            new AppMenuItem('库存盘点', 'Pages.StockTaking', 'flaticon-location', '/app/stocktaking', [],
                [
                    new AppMenuItem(
                        '盘点单据',
                        'Pages.StockTaking.Create',
                        'flaticon-list-3',
                        '/app/finproduct/stocktaking/'
                    )
                ]
            ),
            // new AppMenuItem('辅助功能', 'Pages.Auxiliary', 'flaticon-tabs', '/app/auxiliary', [],
            //     [
            //         // new AppMenuItem('我的审核', 'Pages.Auxiliary.Audit', 'flaticon-tabs', '/app/auxiliary/audit/'),
            //         new AppMenuItem('Notifications', '', 'flaticon-alarm', '/app/notifications'),
            //     ]
            // ),
            new AppMenuItem('出库管理', 'Pages.FinProduct.Out', 'flaticon-cart', '/app/finproduct/out', [],
                [
                    new AppMenuItem(
                        '手动出库',
                        'Pages.FinProduct.Out.Handle',
                        'flaticon-truck',
                        '/app/finproduct/outhandle/'
                    ),
                    new AppMenuItem(
                        '出库退货',
                        'Pages.FinProduct.Out.Return',
                        'flaticon-bag',
                        '/app/finproduct/outreturn/'
                    ),
                    new AppMenuItem(
                        '出库换货',
                        'Pages.FinProduct.Out.Change',
                        'flaticon-interface-4',
                        '/app/finproduct/outchange/'
                    ),
                    new AppMenuItem(
                        '出库查询',
                        'Pages.FinProduct.Out.Select',
                        'flaticon-list-2',
                        '/app/outbound/query'
                    ),
                    // new AppMenuItem(
                    //     '出库统计',
                    //     'Pages.FinProduct.Out.Statistics',
                    //     'flaticon-interface-8',
                    //     '/app/finproduct/out/statistics/'
                    // ),
                ]
            ),
            // new AppMenuItem('质量检测', 'Pages.FileException', 'flaticon-dashboard', '/app/fileexception/',[],
            // [
            //     new AppMenuItem(
            //         '质检标准',
            //         'Pages.FileException.Standard',
            //         'flaticon-tabs',
            //         '/app/fileexception/standard/list/'
            //     ),
            //     new AppMenuItem(
            //         '质检信息',
            //         'Pages.FileException',
            //         'flaticon-interface-8',
            //         '/app/fileexception/list'
            //     )
            // ]),
            new AppMenuItem('基础信息', 'Pages.Resource', 'flaticon-cogwheel', '', [],
                [
                    new AppMenuItem(
                        '货物信息',
                        'Pages.Resource.ProductInfo',
                        'flaticon-book',
                        '/app/basic/prod-info'
                    ),
                    new AppMenuItem(
                        '供应商管理',
                        'Pages.Resource.Suppliers',
                        'flaticon-interface-8',
                        '/app/basic/supplier-setting'
                    ), 
                    new AppMenuItem(
                        '客户管理',
                        'Pages.Resource.CusSuppliers',
                        'flaticon-interface-8',
                        '/app/basic/cussupplier-setting'
                    ),
                    new AppMenuItem(
                        '仓库信息',
                        'Pages.Resource.Location',
                        'flaticon-pin',
                        '/app/basic/location'
                    ),
                    // new AppMenuItem(
                    //     '部门信息',
                    //     'Pages.Resource.OrganizationUnits',
                    //     'flaticon-map',
                    //     '/app/admin/organization-units'
                    // ),
                    // new AppMenuItem(
                    //     '地区信息',
                    //     'Pages.Resource.Area',
                    //     'flaticon-tabs',
                    //     '/app/basic/area'
                    // ),
                    // new AppMenuItem(
                    //     '品牌信息',
                    //     'Pages.Resource.Brand',
                    //     'flaticon-tabs',
                    //     '/app/basic/brand'
                    // ),
                    // new AppMenuItem(
                    //     '标签模板',
                    //     'Pages.Resource.QrCodeTemp',
                    //     'flaticon-tabs',
                    //     '/app/basic/qrcodetemp'
                    // ),
                ]
            ),
            new AppMenuItem('系统维护', 'Pages', 'flaticon-settings', '', [],
                [
                    // new AppMenuItem(
                    //     '全局配置',
                    //     'Pages.Administration.WholeSetting',
                    //     'flaticon-interface-8',
                    //     '/app/admin/whole'
                    // ),
                    // new AppMenuItem(
                    //     '录单配置',
                    //     'Pages.Administration.InputOrderSetting',
                    //     'flaticon-interface-8',
                    //     '/app/admin/inputorder'
                    // ),
                    // new AppMenuItem(
                    //     '其他配置',
                    //     'Pages.Administration.OtherSetting',
                    //     'flaticon-interface-8',
                    //     '/app/admin/other'
                    // ),
                    new AppMenuItem(
                        '企业信息',
                        'Pages.Administration.EnterpriseSetting',
                        'flaticon-interface-9',
                        '/app/system-config/corp'
                    ),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                    new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                    // new AppMenuItem(
                    //     '单据编号',
                    //     'Pages.Administration.CodeRuleSetting',
                    //     'flaticon-interface-8',
                    //     '/app/admin/coderule'
                    // ),
                    // new AppMenuItem(
                    //     '单据审核',
                    //     'Pages.Administration.StockAuditSetting',
                    //     'flaticon-interface-8',
                    //     '/app/admin/stockaudit'
                    // ),
                ]
            ),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
