export class ObjectUtil {
    static checkObjectComplete(obj) {
        //如果obj为数组，且数组长度为0则直接返回false
        if (obj instanceof Array && obj.length <= 0) {
            return false
        }
        for (let key in obj) {
            const value = obj[key]
            //如果value为数组
            if (value instanceof Array) {
                //长度为0返回false，长度不为0判断数组内所有数据
                if (value.length <= 0) {
                    return false
                } else {
                    let arrayComplete = true
                    value.forEach((it) => {
                        if (!ObjectUtil.checkObjectComplete(it)) {
                            arrayComplete = false
                        }
                    })
                    if (!arrayComplete) {
                        return false
                    }
                }
            } else if (typeof (value) === 'object') {
                if (value == null || value == undefined) {
                    return false
                }
                if (!ObjectUtil.checkObjectComplete(value)) {
                    return false
                }
            } else if (typeof (value) === 'boolean') {
                if (value == null || value == undefined)
                    return false
            } else {
                if (value == null || value == '' || value == undefined)
                    return false
            }
        }
        return true
    }
    static cloneObject(val) {
        // console.log('cloneObject')
        // console.log(val)
        return JSON.parse(JSON.stringify(val))
    }
    static structuralClone(obj): Promise<any> {
        return new Promise(resolve => {
            const {
                port1,
                port2
            } = new MessageChannel()
            port2.onmessage = ev => resolve(ev.data);
            port1.postMessage(obj)
        })
    }
    /**
     * 创建监听对象
     * @param obj 监听对象
     * @param changeCallBack 监听回调
     * @param monitorFieldList 监听属性列表，如果不在里边则只赋值，不监听
     * @returns 添加了监听方法的对象
     */
    static createMonitorObject(obj, changeCallBack, monitorFieldList = []) {
        let itemData = {}
        Object.keys(obj).forEach(filedName => {
            if (monitorFieldList.length > 0 && monitorFieldList.includes(filedName)) {
                Object.defineProperty(itemData, filedName, {
                    get() {
                        return this[`_${filedName}`]
                    },
                    set(newVal) {
                        this[`_${filedName}`] = newVal
                        changeCallBack(filedName, newVal, itemData)
                    }
                })
            }
            //初始化数据
            itemData[filedName] = obj[filedName]
        })
        return itemData
    }
}