import { AfterViewInit, Component, inject, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProductInfoDto, ProductInfoServiceProxy, ProductQuery } from '@shared/service-proxies/service-proxies';
import { MessageService } from 'abp-ng2-module';
import { LazyLoadEvent } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'prod-select',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [appModuleAnimation()],
})
export class SelectProductComponent extends AppComponentBase implements OnInit, AfterViewInit {
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('paginator', { static: true }) paginator: Paginator;
  columns = [
    { label: '编号', align: 'left', width: '', /* sort: true, */ field: 'code', icon: '' },
    { label: '名称', align: 'left', width: '', /* sort: true, */ field: 'name', icon: '' },
    { label: '型号', align: 'left', width: '', /* sort: true, */ field: 'model', icon: '' },
    { label: '规格', align: 'left', width: '', /* sort: true, */ field: 'spec', icon: '' },
    { label: '状态', align: 'left', width: '', sort: true, field: 'deactive', icon: '', type: 'tag', class: { false: 'tag-green', true: 'tag-red' }, value: { false: '启用', true: '停用' } },
    { label: '计量单位', align: 'left', width: '', /* sort: true, */ field: 'unit', icon: '' },
    { label: '品牌', align: 'left', width: '', /* sort: true, */ field: 'brand', icon: '' },
    { label: '预留1', align: 'left', width: '', /* sort: true, */ field: 'obligate1', icon: '' },
    { label: '预留2', align: 'left', width: '', /* sort: true, */ field: 'obligate2', icon: '' },
    { label: '预留3', align: 'left', width: '', /* sort: true, */ field: 'obligate3', icon: '' },
    { label: '预留4', align: 'left', width: '', /* sort: true, */ field: 'obligate4', icon: '' },
    { label: '预留5', align: 'left', width: '', /* sort: true, */ field: 'obligate5', icon: '' },
    { label: '预留6', align: 'left', width: '', /* sort: true, */ field: 'obligate6', icon: '' },
  ];

  options: any[] = [
    { name: '全部', value: undefined },
    { name: '启用', value: false },
    { name: '停用', value: true },
  ];

  visible = false;
  searchData = new ProductInfoDto({
    id: undefined,
    code: '',
    name: '',
    model: '',
    spec: '',
    unit: '',
    category: '',
    brand: '',
    upLimit: undefined,
    downLimit: undefined,
    deactive: undefined,
    obligate1: undefined,
    obligate2: undefined,
    obligate3: undefined,
    obligate4: undefined,
    obligate5: undefined,
    obligate6: undefined,
  });
  selectedProducts: ProductInfoDto[];
  codes;
  $subject = new BehaviorSubject<ProductInfoDto[]>(null);

  constructor(injector: Injector,
    private service: ProductInfoServiceProxy,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public message: MessageService) {
    super(injector);
  }
  ngOnInit(): void {
    this.codes = this.config.data.codes;
  }

  ngAfterViewInit(): void {
    this.load();
  }

  search() {
    this.load();
    this.visible = false;
  }

  load(event?: LazyLoadEvent) {
    if (this.primengTableHelper.shouldResetPaging(event)) {
      this.paginator.changePage(0);

      if (this.primengTableHelper.records && this.primengTableHelper.records.length > 0) {
        return;
      }
    }

    this.primengTableHelper.showLoadingIndicator();

    this.service.paged(
      new ProductQuery({
        codes: this.codes,
        code: this.searchData.code,
        name: this.searchData.name,
        model: this.searchData.model,
        spec: this.searchData.spec,
        unit: this.searchData.unit,
        brand: this.searchData.brand,
        category: this.searchData.category,
        deactive: this.searchData.deactive,
        sorting: this.primengTableHelper.getSorting(this.dataTable),
        skipCount: this.primengTableHelper.getSkipCount(this.paginator, event),
        maxResultCount: this.primengTableHelper.getMaxResultCount(this.paginator, event)
      })
    ).subscribe((result) => {
      this.primengTableHelper.records = result.items;
      this.primengTableHelper.totalRecordsCount = result.totalCount;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }

  save() {
    this.ref.close(this.selectedProducts.filter(f => !f.deactive));
  }
}
