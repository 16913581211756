import { AfterViewInit, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ObjectUtil } from '@shared/utils/object.util';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { GeneralModalService } from '../general-modal.service';

@Component({
  selector: 'app-base-modal-content',
  templateUrl: './base-modal-content.component.html',
  styleUrls: ['./base-modal-content.component.scss'],
})
export class BaseModalContentComponent implements AfterViewInit {
  @Input() value: any
  @Input() _generalModalService: GeneralModalService
  @ViewChild("ContentContainer", { read: ViewContainerRef }) ContentContainer: ViewContainerRef
  _component: any = null
  private _compRef = null

  get ContentComponent() {
    return this._component
  }
  _loading: boolean = false
  get loading() {
    return this._loading
  }
  set loading(val: boolean) {
    this._loading = val
    this.cdf.detectChanges()
  }
  _loadingTips: string = ''
  get loadingTips() {
    return this._loadingTips
  }
  set loadingTips(val: string) {
    this._loadingTips = val
    this.cdf.detectChanges()
  }
  _saving: boolean = false
  get saving() {
    return this._saving
  }
  set saving(val: boolean) {
    this._saving = val
  }


  constructor(protected injector: Injector,
    private nzModelRef: NzModalRef,
    private cdf: ChangeDetectorRef) {

  }
  submit() {
    this._compRef.instance.submit()
  }
  ngAfterViewInit(): void {
    this._component = this.value['ContentComponent']
    this.createComp()
    this.cdf.detectChanges()
  }
  createComp() {
    this.ContentContainer.clear()
    const componentRef = this.ContentContainer.createComponent<any>(this._component, { index: 0, injector: this.injector })
    let newValue = ObjectUtil.cloneObject(this.value)
    delete newValue['ContentComponent']
    componentRef.instance.value = newValue
    componentRef.instance._generalModalService = this._generalModalService
    componentRef.instance.parent = this
    this._compRef = componentRef
  }
  close() {
    if (this._compRef.close && this._compRef.close instanceof Function) {
      this._compRef.close()
    } else {
      this.nzModelRef.close()
    }
  }
}
