import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Injector, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ObjectUtil } from '@shared/utils/object.util';
import { Subscription } from 'rxjs';
import { GeneralModalService } from '../general-modal.service';
import { GeneralModalBase } from '../GeneralModalBase';

@Component({
  selector: 'app-full-screen-page-modal',
  templateUrl: './full-screen-page-modal.component.html',
  styleUrls: ['./full-screen-page-modal.component.scss'],
})
export class FullScreenPageModalComponent extends GeneralModalBase implements AfterViewInit {

  @Input() value: any
  @Input() _generalModalService: GeneralModalService
  @ViewChild("ContentContainer", { read: ViewContainerRef }) ContentContainer: ViewContainerRef
  _component: any = null
  private _compRef = null
  //通过createOrUpdateModal注入
  _title: string = ''

  _loading: boolean = false
  get loading() {
    return this._loading
  }
  set loading(val: boolean) {
    this._loading = val
    this.cdf.detectChanges()
  }
  _loadingTips: string = ''
  get loadingTips() {
    return this._loadingTips
  }
  set loadingTips(val: string) {
    this._loadingTips = val
    this.cdf.detectChanges()
  }

  //header中的附加组件
  headerExtraTemplate: TemplateRef<any>

  private headerExtraTemplateSubscription: Subscription = null

  get ContentComponent() {
    return this._component
  }
  get Title() {
    return this._title
  }

  constructor(protected injector: Injector) {
    super(injector)
  }
  submit() {
    this._compRef.instance.submit()
  }
  ngAfterViewInit(): void {
    this._component = this.value['ContentComponent']
    this._title = this.value['title']
    this.createComp()
    this.cdf.detectChanges()
  }
  createComp() {
    this.ContentContainer.clear()
    const componentRef = this.ContentContainer.createComponent<any>(this._component, { index: 0, injector: this.injector })
    let newValue = ObjectUtil.cloneObject(this.value)
    delete newValue['ContentComponent']
    componentRef.instance.value = newValue
    componentRef.instance._generalModalService = this._generalModalService
    componentRef.instance.parent = this
    if (componentRef.instance['headerExtraTemplateLoaded']) {
      this.headerExtraTemplateSubscription = componentRef.instance.headerExtraTemplateLoaded.subscribe(c => {
        this.headerExtraTemplate = c
        this.cdf.detectChanges()
      })
    }
    this._compRef = componentRef
  }

  onBack() {
    this.nzModalRef.destroy()
    if (this.headerExtraTemplateSubscription) {
      this.headerExtraTemplateSubscription.unsubscribe()
    }
  }

}
