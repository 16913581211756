import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { UserSelectorComponent } from "@app/shared/common/user-selector-modal/user-selector-modal.component";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    CreateOrUpdateFileExcetpionInput,
    FileExceptionDto,
} from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";

@Component({
    selector: "AddTaskExceptionModal",
    templateUrl: "./add-or-update-exception.component.html",
    styleUrls: ["./add-or-update-exception.component.less"],
})
export class AddOrUpdateExceptionComponent
    extends AppComponentBase
    implements OnInit {
    @ViewChild('UserSelectorModal') userSelectorModal: UserSelectorComponent
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Input() groupId;
    displayModal = false;
    editData: CreateOrUpdateFileExcetpionInput = new CreateOrUpdateFileExcetpionInput();
    saving: boolean = false;
    changedEvent: any = "";
    files: string[] = [];

    handleName;
    constructor(injector: Injector,
        ) {
        super(injector);
    }

    ngOnInit(): void { }

    async selectHandlesUser() {
        var selectedUser = await this.userSelectorModal.show();
        this.editData.handlesPersonId = selectedUser.id;
        this.handleName = selectedUser.surname + selectedUser.name;
    }

    uploadSuccess(filename: string) {
        this.files.push(filename);
    }

    deleteMedia(filename) {
        this.files = this.files.filter(f => f != filename);
    }

    async show(taskId,stepid,groupId, data?: FileExceptionDto | undefined) {
        const self = this;
        self.changedEvent = undefined;
        self.displayModal = true;
        this.editData.taskId = taskId;
        this.editData.stepId = stepid;
        this.editData.groupId = groupId;
        if (data) {
            this.editData.id = data.id;
            this.editData.description = data.description;
        }
    }
    close(): void {
        this.displayModal = false;
        this.modalSave.emit(null);
    }
    valid(): boolean {
        if (!this.editData.handlesPersonId) {
            this.message.error("请选择处理人！");
            return false;
        }
        if (!this.editData.description) {
            this.message.error("请确认填写了问题描述！");
            return false;
        }
        return true;
    }
    save() {
        if (!this.valid()) {
            return;
        }
        this.message.confirm(
            '确认保存吗?',
            `提醒`,
            isConfirmed => {
                if (isConfirmed) {
                    this.saving = true;
                    this.editData.mediaFiles = this.files.map(c => c).join(',');
                    // this._service
                    //     .updateException(this.editData)
                    //     .pipe(finalize(() => (this.saving = false)))
                    //     .subscribe(async (response) => {
                    //         this.notify.success("保存成功");
                    //         this.onChange();
                    //         this.close();
                    //     });
                }
            });
    }

    onChange() {
        abp.event.trigger('app.taskopt.onChange');
    }
}
