import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { SelectLabelValue } from './SelectLabelValue';

@Component({
  selector: 'app-modal-input-select',
  templateUrl: './modal-input-select.component.html',
  styleUrls: ['./modal-input-select.component.scss'],
})
export class ModalInputSelectComponent implements OnInit {

  @Input() value: string = ''
  @Input() items: SelectLabelValue[] = []

  constructor(private nzModalRef: NzModalRef) { }

  ngOnInit() { }
  submit() {
    this.nzModalRef.destroy(this.value)
  }

}
