import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AutoCompleteCompleteEvent } from '@shared/models/AutoCompleteCompleteEvent';
import { FinProductOutServiceProxy, RClassificationListDto, RClassificationServiceProxy, SupplierServiceProxy } from '@shared/service-proxies/service-proxies';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs';
import { selectOutOrderProductComponent } from './select-order-product.component';
@Component({
  selector: 'select-order',
  templateUrl: './select-order.component.html',
  styleUrls: ['./select-order.component.css'],
  providers: [DialogService]
})
export class SelectOutOrderComponent extends AppComponentBase implements AfterViewInit, OnInit {
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('paginator', { static: true }) paginator: Paginator;

  visible = false;
  columns = [
    { label: '单据类型', align: 'left', width: '', /* sort: true, */ field: 'orderType', icon: '' },
    { label: '日期', align: 'left', width: '', /* sort: true, */ field: 'transcriptionDateStr', icon: '' },
    { label: '订单编号', align: 'left', width: '', /* sort: true, */ field: 'billCode', icon: '' },
    { label: '经手人', align: 'left', width: '', /* sort: true, */ field: 'handlerId', icon: '', type: 'user', },
    // { label: '仓库', align: 'left', width: '', /* sort: true, */ field: 'rClassificationId', icon: '', type:'classification' },
    { label: '客户名称', align: 'left', width: '', /* sort: true, */ field: 'supplierId', icon: '', type: 'supp', },
    { label: '备注', align: 'left', width: '', /* sort: true, */ field: 'remark', icon: '' },
  ];

  suppliers: any[] = [];
  selectSupp: any;
  code = '';
  name = '';
  effectiveDateStrBegin;
  effectiveDateStrEnd;
  includeRelease: boolean = true;
  includeReleaseReturn: boolean = true;
  includeReleaseChange: boolean = true;
  sorting = undefined;
  skipCount = 0;
  maxResultCount = 10
  classes: RClassificationListDto[] = [];
  selectClass: RClassificationListDto;
  constructor(injector: Injector,
    private _suppService: SupplierServiceProxy,
    private _outservice: FinProductOutServiceProxy,
    public config: DynamicDialogConfig,
    private dService: DialogService,
    public ref: DynamicDialogRef,
    private _classService: RClassificationServiceProxy,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._suppService.getComboxSupplierList("",1).subscribe((result) => {
      this.suppliers = result;
    });
  }

  onClassBlur() {
    if (!this.selectClass?.id)
      this.selectClass = undefined;
  }
  searchClass(event: AutoCompleteCompleteEvent) {
    this._classService.getComboxClassificationList(event.query).subscribe((result) => {
      this.classes = result;
    });
  }

  selectBill(id) {
    const ref = this.dService.open(selectOutOrderProductComponent, {
      data: {
        type: '出库单',
        id: id,
      },
      header: '明细',
      width: '80vw'
    });
    ref.onClose.subscribe(result => {
      if (result)
        this.ref.close(result);
    });
  }

  ngAfterViewInit(): void {
    let rClassificationId = this.config.data.data;
    console.log(this.config.data.data);
    if (rClassificationId) {
      this._classService.getComboxClassificationList('').subscribe((result) => {
        this.classes = result;
        this.selectClass = result.find(f => f.id === parseInt(rClassificationId));
        this.load();
      });
    }
    else
      this.load();
  }

  search() {
    this.visible = false;
    this.load();
  }

  load(event?: LazyLoadEvent) {
    if (this.primengTableHelper.shouldResetPaging(event)) {
      this.paginator.changePage(0);

      if (this.primengTableHelper.records && this.primengTableHelper.records.length > 0) {
        return;
      }
    }

    this.primengTableHelper.showLoadingIndicator();
    this.sorting = this.primengTableHelper.getSorting(this.dataTable);
    this.skipCount = this.primengTableHelper.getSkipCount(this.paginator, event);
    this.maxResultCount = this.primengTableHelper.getMaxResultCount(this.paginator, event);
    this._outservice.getFinPickOrder(
      this.code,
      this.name,
      this.selectSupp?.id,
      this.selectClass?.id.toString(),
      this.effectiveDateStrBegin,
      this.effectiveDateStrEnd,
      this.includeRelease,
      false,
      false,
      "",
      this.sorting,
      this.maxResultCount,
      this.skipCount
    ).pipe(finalize(() => {
      this.primengTableHelper.hideLoadingIndicator();
    })).subscribe((result) => {
      this.primengTableHelper.records = result.items;
      this.primengTableHelper.totalRecordsCount = result.totalCount;
    });
  }

  getSupp(id) {
    return this.suppliers.find(s => s.id == id)?.supplierShortName;
  }

  searchSupp(event: AutoCompleteCompleteEvent) {
    this._suppService.getComboxSupplierList(event.query,1).subscribe((result) => {
      this.suppliers = result;
    });
  }
}
