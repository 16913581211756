<div appBsModal #editUpExceptionModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="auditLogExceptionModal" aria-hidden="true" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          <span>查看</span>
        </h4>
        <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <!-- <p-tabView> -->
          <!-- <p-tabPanel header="问题"> -->
            <div class="form-group">
              <label for="code">问题描述: <span class="text-danger"></span></label>
              {{exception.description}}
            </div>
            <div class="form-group">
              <pic-list *ngIf="files.length" [files]="files" (modalSave)="deleteMedia($event)"></pic-list>
            </div>
          <!-- </p-tabPanel> -->
          <!-- <p-tabPanel header="处理过程"> -->
            <!-- <app-timeline-flow [data]="statusData"></app-timeline-flow> -->
          <!-- </p-tabPanel> -->
        <!-- </p-tabView> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light-primary font-weight-bold" (click)="close()">{{"Close" |
          localize}}</button>
      </div>
    </div>
  </div>
</div>