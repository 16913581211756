<div>
    <div [class]="containerClass">
        <div class="card card-custom gutter-b">
            <div class="card-body primeng-noline" [busyIf]="primengTableHelper.isLoading">
                <p-table #dataTable sortMode="multiple" (onLazyLoad)="load($event)" [value]="primengTableHelper.records"
                    rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [scrollable]="true" ScrollWidth="100%"
                    scrollDirection="horizontal" [paginator]="false" [responsive]="primengTableHelper.isResponsive"
                    [resizableColumns]="primengTableHelper.resizableColumns">
                    <ng-template pTemplate="header">
                        <tr>
                            <ng-container *ngFor="let col of columns">
                                <ng-container *ngIf="col.sort">
                                    <th [pSortableColumn]="col.field" [style]="{ 'text-align': col.align }">
                                        <div>
                                            {{ col.label }}
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </div>
                                    </th>
                                </ng-container>
                                <ng-container *ngIf="!col.sort">
                                    <th [ngStyle]="{ 'text-align': col.align }">
                                        {{ col.label }}
                                    </th>
                                </ng-container>
                            </ng-container>
                            <!-- <th [ngStyle]="{ 'text-align': 'left'}">货物总价</th> -->
                            <th style="width: 150px; text-align: center">操作</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record="$implicit">
                        <tr>
                            <ng-container *ngFor="let col of columns">
                                <ng-container *ngIf="!col.type">
                                    <td>{{ record[col.field] }}</td>
                                </ng-container>
                                <ng-container *ngIf="col.type === 'supp'">
                                    <td>{{ getSupp(record[col.field]) }}</td>
                                </ng-container>
                                <ng-container *ngIf="col.type === 'user'">
                                    <td>{{ record[col.field] | userFormat }}</td>
                                </ng-container>
                                <ng-container *ngIf="col.type === 'classification'">
                                    <td>{{ record[col.field] | classificationFormat }}</td>
                                </ng-container>
                                <ng-container *ngIf="col.type === 'date'">
                                    <td>{{ record[col.field] | luxonFormat : 'yyyy-LL-dd' }}</td>
                                </ng-container>
                                <ng-container *ngIf="col.type === 'tag'">
                                    <td>
                                        <span class="tab-tag" [ngClass]="col.class[record[col.field]]">
                                            <ng-container *ngIf="col.value">
                                                {{ col.value[record[col.field]] ?? record[col.field] }}
                                            </ng-container>
                                            <ng-container *ngIf="!col.value">
                                                {{ record[col.field] }}
                                            </ng-container>
                                        </span>
                                    </td>
                                </ng-container>
                            </ng-container>
                            <!--<td>
                                <span>{{ record['f_RealInventory'] * record['price'] | number: '1.2-4' }}</span>
                            </td>-->
                            <td style="text-align: center">
                                <div class="flex align-items-center justify-content-center gap-2">
                                    <a class="text-success mr-2" (click)="selectBill(record.id)">明细</a>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                    {{ 'NoData' | localize }}
                </div>
                <div class="primeng-paging-container">
                    <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                        (onPageChange)="load($event)" [totalRecords]="primengTableHelper.totalRecordsCount"
                        [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                        [showCurrentPageReport]="true" [currentPageReportTemplate]="
                            'TotalRecordsCount' | localize : primengTableHelper.totalRecordsCount
                        "></p-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <p-dialog [(visible)]="visible" header="查询条件">
    <div>
        <div class="row mx-lg-n5" style="width: 70vw; padding: 0 0.6rem">
            <div class="col flex flex-column">
                <div class="primary-tag-title mb-6">基本信息</div>
                <div class="card card-border border-secondary">货物
                    <div class="card-body">
                        <div class="row mb-4 form-co">
                            <div class="col-3 col-form-label text-end">产品编号</div>
                            <div class="col-9"><input pInputText [(ngModel)]="code" type="text" class="form-control" />
                            </div>货物
                        </div>
                        <div class="row mb-4">
                            <div class="col-3 col-form-label text-end">产品名称</div>
                            <div class="col-9"><input pInputText [(ngModel)]="name" type="text" class="form-control" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3 col-form-label text-end">客户名称</div>
                            <div class="col-9 flex">
                                <p-autoComplete id="suppliers" [(ngModel)]="selectSupp" class="flex-input"
                                    field="supplierShortName" [suggestions]="suppliers"
                                    (completeMethod)="searchSupp($event)" inputId="float-label"
                                    [inputStyleClass]="'form-control'"></p-autoComplete>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col flex flex-column">
                <div class="primary-tag-title mb-6">选择日期</div>
                <div class="card card-border border-secondary">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col-3 col-form-label text-end">开始时间</div>
                            <div class="col-9">
                                <p-calendar [(ngModel)]="effectiveDateStrBegin" id="effectiveDateStrBegin"
                                    appendTo="body" [showIcon]="true" inputId="float-label"></p-calendar>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-3 col-form-label text-end">结束时间</div>
                            <div class="col-9">
                                <p-calendar [(ngModel)]="effectiveDateStrEnd" id="effectiveDateStrEnd" appendTo="body"
                                    [showIcon]="true" inputId="float-label"></p-calendar>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-9 offset-3">
                                <p-checkbox [binary]="true" [value]="1" label="保存时间条件" inputId="binary"></p-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col flex flex-column">
                <div class="primary-tag-title mb-6">选择单据</div>
                <div class="card card-border border-secondary">
                    <div class="card-body">
                        <div class="row mb-4">
                            <div class="col">
                                <p-checkbox [(ngModel)]="includeRelease" [binary]="true" [value]="0" label="入库单"
                                    inputId="binary"></p-checkbox>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <p-checkbox [(ngModel)]="includeReleaseReturn" [binary]="true" [value]="1" label="退货单"
                                    inputId="binary"></p-checkbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p-checkbox [(ngModel)]="includeReleaseChange" [binary]="true" [value]="2" label="换货单"
                                    inputId="binary"></p-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-primary" (click)="search()">搜索</button>
    </ng-template>
</p-dialog> -->