<div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
        <div
            id="kt_aside"
            class="aside aside-extended"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="auto"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_toggle"
        >
            <div class="aside-primary d-flex flex-column align-items-lg-center flex-row-auto">
                <div
                    class="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10"
                    id="kt_aside_logo"
                >
                    <theme7-brand></theme7-brand>
                </div>
                <div
                    class="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0"
                    id="kt_aside_nav"
                >
                    <!--begin::Wrapper-->
                    <div
                        class="hover-scroll-overlay-y mb-5 px-5"
                        data-kt-scroll="true"
                        data-kt-scroll-activate="{default: false, lg: true}"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-wrappers="#kt_aside_nav"
                        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                        data-kt-scroll-offset="0px"
                        style="height: 343px"
                    >
                        <!--begin::Nav-->
                        <ul class="nav flex-column w-100" id="kt_aside_nav_tabs" role="tablist"></ul>
                        <!--end::Tabs-->
                    </div>
                    <!--end::Nav-->
                </div>
                <div class="aside-footer d-flex flex-column align-items-center flex-column-auto" id="kt_aside_footer">
                    <active-delegated-users-combo
                        [customStyle]="'btn btn-hover-transparent-white btn-lg mb-2'"
                    ></active-delegated-users-combo>
                    <subscription-notification-bar
                        [customStyle]="
                            'btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light position-relative mb-3'
                        "
                    ></subscription-notification-bar>
                    <quick-theme-selection
                        [customStyle]="
                            'btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light position-relative mb-3'
                        "
                    ></quick-theme-selection>
                    <language-switch-dropdown
                        [customStyle]="
                            'btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light position-relative mb-3'
                        "
                    ></language-switch-dropdown>
                    <header-notifications
                        [isRight]="false"
                        [customStyle]="
                            'btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light position-relative mb-3'
                        "
                    ></header-notifications>
                    <chat-toggle-button
                        [customStyle]="
                            'btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light position-relative mb-3'
                        "
                    ></chat-toggle-button>

                    <toggle-dark-mode
                        [customStyle]="
                            'btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light position-relative mb-3'
                        "
                        [isDarkModeActive]="isDarkModeActive()"
                    ></toggle-dark-mode>

                    <user-menu [togglerCssClass]="'cursor-pointer symbol symbol-40px'"></user-menu>
                </div>
            </div>
            <div class="aside-secondary d-flex flex-row-fluid">
                <div class="aside-workspace my-5 p-5" id="kt_aside_wordspace">
                    <div class="d-flex h-100 flex-column">
                        <side-bar-menu
                            [menuClass]="
                                'menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-5 px-6 my-5 my-lg-0'
                            "
                        ></side-bar-menu>
                    </div>
                </div>
            </div>
            <button
                class="
                    btn btn-sm btn-icon
                    bg-body
                    btn-color-gray-700 btn-active-primary
                    position-absolute
                    translate-middle
                    start-100
                    end-0
                    bottom-0
                    shadow-sm
                    d-none d-lg-flex
                "
                data-kt-toggle="true"
                data-kt-toggle-state="active"
                data-kt-toggle-target="body"
                data-kt-toggle-name="aside-minimize"
                style="margin-bottom: 1.35rem"
            >
                <span class="svg-icon svg-icon-2 rotate-180">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"></rect>
                        <path
                            d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                            fill="black"
                        ></path>
                    </svg>
                </span>
            </button>
        </div>
        <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <div
                id="kt_header"
                class="header d-lg-none"
                data-kt-sticky="true"
                data-kt-sticky-name="header"
                data-kt-sticky-offset="{default: '200px', lg: '300px'}"
            >
                <div class="container-xxl d-flex align-items-center justify-content-between" id="kt_header_container">
                    <div class="d-flex d-lg-none align-items-center ms-n2 me-2">
                        <!--begin::Aside mobile toggle-->
                        <div class="btn btn-icon btn-active-icon-primary" id="kt_aside_toggle">
                            <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
                            <span class="svg-icon svg-icon-1">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="currentColor"
                                    ></path>
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="currentColor"
                                    ></path>
                                </svg>
                            </span>
                            <!--end::Svg Icon-->
                        </div>
                        <!--end::Aside mobile toggle-->
                        <!--begin::Logo-->
                        <a href="/" class="d-flex align-items-center">
                            <theme7-brand [imageClass]="'h-30px'"></theme7-brand>
                        </a>
                        <!--end::Logo-->
                    </div>
                </div>
            </div>
            <router-outlet></router-outlet>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
