<div class="full-screen-wrap">
    <nz-page-header class="site-page-header" [nzTitle]="Title">
        <nz-page-header-extra>
            <ng-container *ngTemplateOutlet="headerExtraTemplate">
            </ng-container>
            <span class="close-btn" (click)="onBack()" nz-icon nzType="close" nzTheme="outline"></span>
        </nz-page-header-extra>
    </nz-page-header>
    <div class="content-wrap">
        <nz-spin class="full-spin" [nzTip]="loadingTips" [nzSpinning]="loading">
            <ng-container #ContentContainer></ng-container>
        </nz-spin>
    </div>
</div>