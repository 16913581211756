import { Injectable } from '@angular/core';
import { LocationServiceProxy, RLocationListDto } from '@shared/service-proxies/service-proxies';

@Injectable({
  providedIn: 'root'
})
export class LocationMapService {
  public static locations: RLocationListDto[] = [];
  constructor(private _service: LocationServiceProxy) { }
  public async reload() {
    LocationMapService.locations = await this._service.getComboxLocationList("",0).toPromise();
  }
  public toUserName(id: number): string {
    let res = LocationMapService.locations.find(p => p.id === id);
    return res ? res.displayName : '';
  }
}
