import { Injector, Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { SubHeaderService } from '@app/shared/common/sub-header/sub-header.service';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [appModuleAnimation()],
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit, OnDestroy {

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    title = '';
    description = '';
    subscribe: Subscription;
    constructor(
        injector: Injector,
        _dateTimeService: DateTimeService,
        @Inject(DOCUMENT) private document: Document,
        private service: SubHeaderService) {
        super(injector, _dateTimeService);

    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        if (this.currentTheme.baseSettings.menu.defaultMinimizedAside) {
            this.document.body.setAttribute('data-kt-aside-minimize', 'on');
        }
        this.subscribe = this.service.info$.subscribe(result => {
            setTimeout(() => {
                this.title = result[0];
                this.description = result[1];
            }, 0);
        });
    }

    ngOnDestroy(): void {
        this.subscribe.unsubscribe();
    }

    getMobileMenuSkin(): string {
        return this.appSession.theme.baseSettings.layout.darkMode ? 'dark' : 'light';
    }
}
