import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Injector, Input, TemplateRef, ViewChild } from "@angular/core"
import { AppComponentBase } from "@shared/common/app-component-base"
import { NzMessageService } from "ng-zorro-antd/message"
import { NzModalRef } from "ng-zorro-antd/modal"
import { GeneralModalService } from "./general-modal.service"
@Component({
    template: ''
})
export abstract class GeneralModalBase extends AppComponentBase implements AfterViewInit {
    value: any
    //和value一样 通过GeneralModalService.createModal自动添加
    _generalModalService: GeneralModalService
    parent: any
    // loading: boolean = false
    protected nzModalRef: NzModalRef
    // protected nzMessage: NzMessageService
    protected cdf: ChangeDetectorRef

    @ViewChild("headerExtraTemplate") headerExtraTemplate: TemplateRef<any>
    headerExtraTemplateLoaded: EventEmitter<any> = new EventEmitter<any>()



    constructor(injector: Injector) {
        super(injector)
        this.nzModalRef = injector.get(NzModalRef)
        // this.nzMessage = injector.get(NzMessageService)
        this.cdf = injector.get(ChangeDetectorRef)

    }
    ngAfterViewInit(): void {
        this.cdf.detectChanges()
        if (this.headerExtraTemplate) {
            this.headerExtraTemplateLoaded.emit(this.headerExtraTemplate)
        }
    }

    getValue(key: string) {
        if (!this.value || this.value[key] == undefined) {
            return null
        }
        return this.value[key]
    }

    setSaving() {
        this.parent.saving = true
    }
    cancelSaving() {
        this.parent.saving = false
    }
    setLoading(tips: string = '加载中') {
        this.parent.loading = true
        this.parent.loadingTips = tips
    }
    get getLoading() {
        return this.parent.loading
    }
    cancelLoading() {
        this.parent.loading = false
    }
    abstract submit()
    close() {
        this.nzModalRef.triggerCancel()
    }
}