import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-modal-view-text',
  templateUrl: './modal-view-text.component.html',
  styleUrls: ['./modal-view-text.component.scss'],
})
export class ModalViewTextComponent implements OnInit {
  @Input() value: string = ''

  constructor(private nzModalRef: NzModalRef) { }

  ngOnInit() { }
  submit() {
    this.nzModalRef.destroy(this.value)
  }
}
