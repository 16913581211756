import { Pipe, PipeTransform } from '@angular/core';
import { UserMapService } from './services/user-map.service';

@Pipe({
  name: 'userFormat'
})
export class UserFormatPipe implements PipeTransform {
  constructor(private map: UserMapService) { }
  transform(value: string, ...args: unknown[]): string {
    if (value)
      return this.map.toUserName(value);
  }
}
