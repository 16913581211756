import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from "@angular/common/http";
import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { AppComponentBase } from "@shared/common/app-component-base";
import { FileUpload } from 'primeng/fileupload';

@Component({
    selector: "pic-upload",
    styles: [':host{display:block;position:relative;flex:1 auto}'],
    template: `
    <p-progressBar *ngIf="pgshow" [value]="pgvalue"></p-progressBar>
    <p-fileUpload id="file" #upfile cancelLabel="清空文件" uploadLabel="上传文件" chooseLabel="选择文件"
        name="fileAttachment" [customUpload]="true"
        (uploadHandler)="onUpload($event)" (onProgress)="onBeforeSend($event)">
        <ng-template *ngIf="file" pTemplate="content">
            {{file?.name}} - {{file?.size}}
            
        </ng-template>
    </p-fileUpload>
  `
})
export class PicUploadComponent extends AppComponentBase {
    file: any;
    pgvalue: number;
    pgshow: boolean
    @ViewChild('upfile') upfile: FileUpload;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    constructor(injector: Injector,
        private http: HttpClient) {
        super(injector);
    }

    onUpload(event) {
        for (let file of event.files) {
            this.file = file;
            let docPath = new Date().getTime().toString() + "-" + file.name;
            const formData: FormData = new FormData();
            formData.append('file', file, docPath);
            const req = new HttpRequest('POST',
                AppConsts.remoteServiceBaseUrl + '/api/services/app/Multimedia/UploadFile',
                formData,
                {
                    reportProgress: true,
                }
            );

            this.http.request(req).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    console.log(`File is ${percentDone}% uploaded.`);
                    this.pgshow = true;
                    this.pgvalue = percentDone;
                } else if (event instanceof HttpResponse) {
                    console.log('File is completely uploaded!');
                    this.pgshow = false;
                    this.notify.success("文件上传成功");
                    this.modalSave.emit(docPath);
                    this.upfile.clear();
                }
            });

        }
    }

    onBeforeSend(event): void {
        this.pgshow = true;
    }


}